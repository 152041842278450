









import EventWebpageEditPaneVue from '@/components/EventWebpage/EventWebpageEditPane.vue';
import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {
    EventWebpageEditPaneVue,
  },
})
export default class EventWebPage extends Vue {
}
