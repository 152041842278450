
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Twitter } from 'vue-color';
import vClickOutside from 'v-click-outside';
import Datepicker from '../Common/Datepicker.vue';
import AddURL from '../Common/AddUrl.vue';
import ImageProcess from '../Common/ImageProcess.vue';
import Editor from '@/components/Common/Editor.vue';
import MapPicker from '@/components/Common/MapPicker.vue';
import SubModulePrefix from '@/enums/SubModulePrefix';
import WarningMessageBar from '../Common/WarningMessageBar.vue';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { WarningMessageBarViewModel } from '@/entities/WarningMessageBarViewModel';
import DetailModalPopup from '../Common/DetailModalPopup.vue';
import EventWebPageViewModel from '@/entities/EventWebPages/EventWebPageViewModel';
import EventWebPageButtonType from '@/enums/EventWebPageButtonType';
import { DetailModalPopupViewModel } from '@/entities/DetailModalPopupViewModel';
import loading from '@/components/Common/loading.vue';
import Toaster from '@/components/Common/Toaster.vue';
import Common from '@/helper/Common';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import VueModalPopUp from '../Common/VueModalPopUp.vue';
import { VueModalPopUpViewModel } from '@/entities/VueModalPopUpViewModel';
import EventWebPageHelper from '@/helper/EventWebPageHelper';
import { BrowserSettingsInfo } from '@/entities/BrowserSettingsInfo';
import moment from 'moment-timezone';
import EventWebPageAnalyticsViewModel from '@/entities/EventWebPages/EventWebPageAnalytics/EventWebPageAnalyticsViewModel';
import AnalyticsModalPopupViewModel from '@/entities/AnalyticsModalPopupViewModel';
import AnalyticsModalPopup from '@/components/Common/AnalyticsModalPopup.vue';
import AnalyticsModalPopupDataViewModel from '@/entities/AnalyticsModalPopupDataViewModel';
@Component({
  components: {
    AddURL,
    Editor,
    MapPicker,
    Datepicker,
    ImageProcess,
    WarningMessageBar,
    ValidationSummary,
    DetailModalPopup,
    loading,
    Toaster,
    VueModalPopUp,
    AnalyticsModalPopup,
    Twitter,
    vClickOutside,
  },
})
export default class EventWebpageEditPane extends Vue {
  private PrimaryBackgroundColor = {hex: ''};
  private PrimaryFontColor = {hex: ''};
  private HeaderFooterBackgroundColor = {hex: ''};
  private HeaderFooterFontColor = {hex: ''};
  private ButtonAccentBackgroundColor = {hex: ''};
  private ButtonAccentFontColor = {hex: ''};
  private LinkFontColor = {hex: ''};
  private fontAppearance = EventWebPageHelper.FontAppearance;
  private isMobileView = false;
  // Used to show message when page contains changes and is not saved
  private warningMessage: WarningMessageBarViewModel = WarningMessageBarViewModel.createEmpty();
  // Used to show message when the page is disabled i.e., in case of mobile view
  private informationMessage: WarningMessageBarViewModel = WarningMessageBarViewModel.createEmpty();
  // Used to show message when invitee is not published
  private inviteeWarningMessage: WarningMessageBarViewModel = WarningMessageBarViewModel.createEmpty();
  private isPaletteSelected = [false,false,false,false,false,false,false];
  private organizerImageClicked = false;
  private missingImage: string = require('@/assets/Images/ImageMissingFeature.png');
  private featureMissingImage: string = require('@/assets/Images/icon-placeholder.svg');
  private showFeaturedImage = false;
  private showImageProcess = false;
  private moduleName = 'EventWebPage';
  private subModuleName = '';
  private isPopupVisible = false;
  private isButtonUrlValid = false;
  private addIcon = require('@/assets/Images/add-icon-grey.svg');
  private previewIcon = require('@/assets/Images/preview-icon.svg');
  private infoIcon = require('@/assets/Images/info-icon.svg');
  private copiedIcon: string = require('@/assets/Images/link_icon_small.svg');
  private analyticsIcon: string = require('@/assets/Images/analytics-icon.svg');
  private eventWebPageData: EventWebPageViewModel = EventWebPageViewModel.createEmpty();
  private oldEventWebPageData: EventWebPageViewModel = EventWebPageViewModel.createEmpty();
  private detailModalPopupData: DetailModalPopupViewModel = DetailModalPopupViewModel.createEmpty();
  private isPageDirty = false;
  private showLoading = false;
  private showToaster = false;
  private toasterDefaultTimeout = EventWebPageHelper.ToasterTimeOut;
  private toasterMessage = '';
  private loaderBorderColor = '';
  private minDateTime = new Date().toISOString();
  private isValidationSummaryVisible = false;
  private validationErrorFields: string[] = [];
  private popupButton1Text = '';
  private popupButton2Text = '';
  private staticPath = process.env.VUE_APP_EVENTWEBPAGEURL;
  private isPublished = false;
  private isSavedAndPublished = false;
  private maxTitleLength = EventWebPageHelper.MaxTitleLength;
  private maxInputFieldLength = EventWebPageHelper.maxInputFieldLength;
  private showVueModalPopUp = false;
  private publishModalPopUp: VueModalPopUpViewModel = VueModalPopUpViewModel.createEmpty();
  private eventPaths: string[] = [];
  private storeHelper: StoreHelper = new StoreHelper(this.$store);
  private maxNavBarItems = EventWebPageHelper.MaxNavBarItems;
  private isIEbrowser = false;
  private timeZones = this.storeHelper.GetActiveEventIanaTimeZoneFromStore();
  private dateFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat();
  private isUrlRequired = true;
  private externalLinkUrl = '';
  private register = 'Register';
  private showOverlayInfo = false;
  private showCopiedMessage = false;
  private linkCopiedFadeoutTime = EventWebPageHelper.LinkCopiedFadeoutTime;
  private analyticsModalPopUp: AnalyticsModalPopupViewModel = AnalyticsModalPopupViewModel.createEmpty();
  private showAnalyticsModalPopup = false;
  private isDataLoaded = false;
  public get selectedButtonType(): typeof EventWebPageButtonType {
    return EventWebPageButtonType;
  }
  private mounted() {
    if (navigator.appName === 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))) {
      this.isIEbrowser = true;
    }
    this.InitializeTimeFormat();
    this.GetEventWebPageData();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Vue.use(vClickOutside);
    // Check if current view is mobile view
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    this.popupButton1Text = this.$t('CancelButton').toString();
    this.popupButton2Text = this.$t('Publish').toString();
    // Setting value for message to be displayed in mobile view
    this.informationMessage = new WarningMessageBarViewModel(
      '',
      this.$t('EventWebpage.WebpageReadOnlyMode').toString(),
      this.$t('InformationAlert').toString(),
    );
    // Setting message to be displayed when page have changes to be saved
    this.warningMessage = new WarningMessageBarViewModel(
      this.infoIcon,
      this.$t('EventWebpage.SaveToReflectChangesInPrevew').toString(),
      this.$t('EventSetting.EventInformation').toString(),
    );
    // Setting message to be displayed when invitee is on but not published yet and selected button type is 'use ventla invite'
    this.inviteeWarningMessage = new WarningMessageBarViewModel(
      this.infoIcon,
      this.$t('EventWebpage.UnpublishedInviteURL').toString(),
      this.$t('EventSetting.EventInformation').toString(),
    );
  }
  // Get data from DB
  private GetEventWebPageData() {
    this.showLoading = true;
    this.$axios
      .post<EventWebPageViewModel>(
      '/EventWebPage/GetEventWebPageViewModel',
    )
      .then((response: any) => {
        // Setting values received
        this.eventWebPageData = JSON.parse(JSON.stringify(response.data));
        this.oldEventWebPageData = JSON.parse(JSON.stringify(response.data));
        if(this.eventWebPageData.EventWebPagesMetaData.CTAButtonType === EventWebPageButtonType.VentlaInviteLink) {
          this.isUrlRequired = false;
        }
        if(this.eventWebPageData.EventWebPagesMetaData.CTAButtonType === EventWebPageButtonType.ExternalLink) {
          this.externalLinkUrl = this.eventWebPageData.EventWebPagesMetaData.CTAButtonURL;
        }
        // Storing all the previous path used in an application
        this.GetEventsPath(this.eventWebPageData.AppId);
        this.IsPageDirty();
        this.SetModalPopupData();
        setTimeout(() => {
          this.showLoading = false;
        }, 1000);
        // Showing page when data is loaded preventing css issue in mac
        if(this.eventWebPageData !== null && this.eventWebPageData !== undefined) {
          this.isDataLoaded = true;
        }
        this.SetDefaultColors();
      })
      .catch(() => {
        this.showLoading = false;
      });
  }
  // Get all events path name in an application
  private GetEventsPath(id: number) {
    this.$axios
      .post<any>(
      '/EventWebPage/GetEventWebPagesPathList',
      {
        appId: id,
      },
    )
      .then((response) => {
        // Storing all the paths by converting them to lower case except the current path
        response.data.forEach((element: string) => {
          if(element.toLowerCase() !== this.eventWebPageData.EventWebPages.Path.toLowerCase()) {
            this.eventPaths.push(element.toLowerCase());
          }
        });
      })
      .catch(() => {
        // error
      });
  }
  // Get web page analytics
  private GetWebPageAnalytics() {
    const eventPath = '/' + this.eventWebPageData.AppEncodedName + '/' + this.eventWebPageData.EventWebPages.EncodedPath;
    this.$axios
      .post<EventWebPageAnalyticsViewModel>(
      '/Analytics/GetEventWebPagesLogs',
      {
        path: eventPath,
      },
    )
      .then((response) => {
        this.analyticsModalPopUp = AnalyticsModalPopupViewModel.createEmpty();
        this.analyticsModalPopUp.Heading = this.$t('EventWebpage.EventPageAnalytics').toString();
        // Setting value for views and interactions on event page
        const viewAndInteractions: AnalyticsModalPopupDataViewModel = {
          SubHeading: this.$t('EventWebpage.EventPageAnalytics.ViewsAndInteractions').toString(),
          Data: [{
            key: this.$t('EventWebpage.EventPageAnalytics.PageViews').toString(),
            value: response.data.TotalPageViews,
          }, {
            key: this.$t('EventWebpage.EventPageAnalytics.ButtonClicks').toString(),
            value: response.data.TotalCTACount,
          }],
        };
        // Setting value for interactions on different devices
        const devicesData = response.data.EventWebPageMobileBrowserLogs.map((item) => {
          if(item.IsMobileBrowser) {
            return {
              key: this.$t('Mobile').toString(),
              value: item.PageViews,
            };
          } else {
            return {
              key: this.$t('Desktop').toString(),
              value: item.PageViews,
            };
          }
        });
        const viewByDevices: AnalyticsModalPopupDataViewModel = {
          SubHeading: this.$t('EventWebpage.EventPageAnalytics.ViewsByDeviceType').toString(),
          Data: devicesData,
        };
        response.data.EventWebPageCountryLogs.sort((item1, item2)=> item2.PageViews - item1.PageViews);
        // Setting value according to the intercation among different origins
        const originsData = response.data.EventWebPageLocationLogs.map((item) => ({key: item.City + ', ' + item.Country, value: item.PageViews}));
        originsData.splice(EventWebPageHelper.MaxOriginToBeDisplayed);
        const viewsByOrigin: AnalyticsModalPopupDataViewModel = {
          SubHeading: this.$t('EventWebpage.EventPageAnalytics.ViewsByTopOrigins').toString(),
          Data: originsData,
        };
        // Adding data to analyticsModalPopUp to pass to the popup component
        this.analyticsModalPopUp.AnalyticsData.push(viewAndInteractions);
        this.analyticsModalPopUp.AnalyticsData.push(viewByDevices);
        this.analyticsModalPopUp.AnalyticsData.push(viewsByOrigin);
        this.showAnalyticsModalPopup = true;
      })
      .catch(() => {
        // error
      });
  }
  // Set default values for palette
  private SetDefaultColors() {
    this.PrimaryBackgroundColor.hex = this.eventWebPageData.EventWebPageThemeSettings.PrimaryBackgroundColor;
    this.PrimaryFontColor.hex = this.eventWebPageData.EventWebPageThemeSettings.PrimaryFontColor;
    this.HeaderFooterBackgroundColor.hex = this.eventWebPageData.EventWebPageThemeSettings.HeaderFooterBackgroundColor;
    this.HeaderFooterFontColor.hex = this.eventWebPageData.EventWebPageThemeSettings.HeaderFooterFontColor;
    this.ButtonAccentBackgroundColor.hex = this.eventWebPageData.EventWebPageThemeSettings.ButtonAccentBackgroundColor;
    this.ButtonAccentFontColor.hex = this.eventWebPageData.EventWebPageThemeSettings.ButtonAccentFontColor;
    this.LinkFontColor.hex = this.eventWebPageData.EventWebPageThemeSettings.LinkFontColor;
  }
  // Used for describing the current state of page
  private GetInformationMessage() {
    if(!this.eventWebPageData.EventWebPages.IsPublished) {
      // If page is not published
      return this.$t('EventWebpage.PageNotPublishedYet');
    } else if(this.eventWebPageData.EventWebPagesMetaData.UpdatedOn > this.eventWebPageData.EventWebPages.PublishedDate) {
      // If page is published and have saved draft yet to be published
      return this.$t('EventWebpage.LatestChangesNotPublishedYet',{lastPublished: this.$t('EventWebpage.LastPublisedDate',{date: this.FormatDateTime(this.eventWebPageData.EventWebPages.PublishedDate)})});
    } else {
      // If page is published and doesn't have any saved draft
      return this.$t('EventWebpage.LastPublisedDate',{date: this.FormatDateTime(this.eventWebPageData.EventWebPages.PublishedDate)});
    }
  }
  private InitializeTimeFormat() {
    const browsersettings: BrowserSettingsInfo = this.storeHelper.GetBrowserSettingsFromStore();
    // if timezone is allowed in application then date times will be formatted accordingly
    if (this.timeZones !== '' && this.timeZones !== null && !this.isIEbrowser) {
      this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: this.timeZones,
      });
    } else {
      this.dateFormat = new Intl.DateTimeFormat(browsersettings.locale, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
    }
  }
  // Used for formatting the date and time
  private FormatDateTime(date: Date) {
    if (date !== null && date !== undefined) {
      const datepublished: Date = moment(date).toDate();
      if (!this.isIEbrowser) {
        // For all browser (except IE) date is formatted with browser settings
        return this.dateFormat.format(datepublished).toUpperCase();
      } else {
        // For IE browser date is formatted manually
        const ieDateForamt = moment(datepublished).tz(this.timeZones).format('DD-MM-YYYY, h:mm a');
        return ieDateForamt;
      }
    }
  }
  // Used for setting the button url to ventla invitee when 'use ventla invitee' is clicked
  private SetVentlaInviteLink() {
    if(this.eventWebPageData.InviteUrl !== null && this.eventWebPageData.InviteUrl !== undefined) {
      this.eventWebPageData.EventWebPagesMetaData.CTAButtonType = EventWebPageButtonType.VentlaInviteLink;
      this.isUrlRequired = false;
      if (this.eventWebPageData.EventWebPagesMetaData.CTAButtonTitle === '') {
        this.eventWebPageData.EventWebPagesMetaData.CTAButtonTitle =this.register;
      }
      this.eventWebPageData.EventWebPagesMetaData.CTAButtonURL = this.eventWebPageData.InviteUrl;
    }
  }
  // Used for removing the ventla invitee link when 'use external link' is clicked
  private SetExternalLink() {
    this.isUrlRequired = true;
    if (this.eventWebPageData.EventWebPagesMetaData.CTAButtonTitle === '') {
      this.eventWebPageData.EventWebPagesMetaData.CTAButtonTitle =this.register;
    }
    this.eventWebPageData.EventWebPagesMetaData.CTAButtonURL = this.externalLinkUrl;
  }
  // Used for setting the data for popup having the path name and check for hiding page from appering in search
  private SetModalPopupData() {
    this.detailModalPopupData = new DetailModalPopupViewModel(this.$t('PublishSettings').toString(),this.$t('EventWebpage.WebAddres').toString(),this.$t('EventWebpage.Indexing').toString(),this.eventWebPageData.AppEncodedName,this.eventWebPageData.EventWebPages.Path, this.eventWebPageData.EventWebPages.EncodedPath, this.staticPath, this.eventPaths, this.$t('EventWebpage.InvalidUrl').toString(), this.eventWebPageData.EventWebPages.HidePageFromSearchEngines,this.$t('EventWebpage.HideFromSearchEngines').toString(), this.popupButton1Text,this.popupButton2Text);
  }
  // Receives boolean value from AddURL stating whether entered URL is valid or not
  private IsValidUrl(val: boolean) {
    this.isButtonUrlValid = val;
  }
  // Setting button url value entered by admin
  private SetUrl(url: string) {
    this.eventWebPageData.EventWebPagesMetaData.CTAButtonURL = url;
    if(this.eventWebPageData.EventWebPagesMetaData.CTAButtonType === EventWebPageButtonType.ExternalLink) {
      this.externalLinkUrl = url;
    }
  }
  // Used to hide any palette opened
  private HideAllPalattes() {
    this.isPaletteSelected.forEach((element, index) => {
      Vue.set(this.isPaletteSelected,index,false);
    });
  }
  // Used to show the particular palette clicked
  private TogglePalette(index: number) {
    this.HideAllPalattes();
    if(!this.isMobileView) {
      Vue.set(this.isPaletteSelected,index,!this.isPaletteSelected[index]);
    }
  }
  // Used to show/hide image process
  private ShowImageProcessSection(): boolean {
    return this.showImageProcess;
  }
  private ClickOnImage(val: boolean) {
    if(!this.isMobileView) {
      this.showImageProcess = val;
      this.showFeaturedImage = false;
      this.subModuleName = SubModulePrefix.IconImage;
    }
  }
  // Returning value of logo image to be displayed
  private GetLocalLogoImageUrl() {
    return this.eventWebPageData.EventWebPagesMetaData.LogoURL === null || this.eventWebPageData.EventWebPagesMetaData.LogoURL === '' ? this.addIcon : this.eventWebPageData.EventWebPagesMetaData.LogoURL;
  }
  // Returning value of banner image to be displayed
  private GetLocalBannerImageUrl() {
    return this.eventWebPageData.EventWebPagesMetaData.BannerImageURL === null || this.eventWebPageData.EventWebPagesMetaData.BannerImageURL === '' ? this.addIcon : this.eventWebPageData.EventWebPagesMetaData.BannerImageURL;
  }
  // Returning value of organizer image to be displayed
  private GetLocalOrganizerImageUrl() {
    return this.eventWebPageData.EventWebPagesMetaData.OrganizerImageUrl === null || this.eventWebPageData.EventWebPagesMetaData.OrganizerImageUrl === '' ? this.addIcon : this.eventWebPageData.EventWebPagesMetaData.OrganizerImageUrl;
  }
  // feature image either banner or organizer based on check i.e., organizerImageClicked stating whether organizer image is clicked or not
  private GetFeaturedImageUrl() {
    if(this.organizerImageClicked) {
      return (this.eventWebPageData.EventWebPagesMetaData.OrganizerImageUrl === null || this.eventWebPageData.EventWebPagesMetaData.OrganizerImageUrl === '') ? this.featureMissingImage : this.eventWebPageData.EventWebPagesMetaData.OrganizerImageUrl;
    } else {
      return (this.eventWebPageData.EventWebPagesMetaData.BannerImageURL === null || this.eventWebPageData.EventWebPagesMetaData.BannerImageURL === '') ? this.featureMissingImage : this.eventWebPageData.EventWebPagesMetaData.BannerImageURL;
    }
  }
  // Changing background image for either banner or organizer section based on particular section clicked
  private ClickOnBackgroundImage(isOrganizerImageClicked: boolean, IsDisabled: boolean) {
    if(!this.isMobileView && !IsDisabled) {
      this.showImageProcess = true;
      this.subModuleName = SubModulePrefix.BackgroundImage;
      this.showFeaturedImage = true;
      this.organizerImageClicked = isOrganizerImageClicked;
    }
  }
  // Set logo image url
  private SetlogoUrl(imgurl: string, _mainMediaFullUrl: string, IsLogoUrl: boolean) {
    if(IsLogoUrl === undefined || IsLogoUrl === false) {
      this.eventWebPageData.EventWebPagesMetaData.LogoURL = imgurl.includes('data:image/png') ? '' : imgurl;
    }
  }
  // Set background image url
  private SetBackgroundImageUrl(
    url: string,
  ) {
    if(this.organizerImageClicked) {
      this.eventWebPageData.EventWebPagesMetaData.OrganizerImageUrl = url === undefined ? '' : url;
    } else {
      this.eventWebPageData.EventWebPagesMetaData.BannerImageURL = url === undefined ? '' : url;
    }
  }
  private SetLogoOldUrl() {
    this.eventWebPageData.EventWebPagesMetaData.LogoURL = this.oldEventWebPageData.EventWebPagesMetaData.LogoURL;
  }
  // Warning message for logo image
  private GetLogoWarning() {
    return this.$t('EventSetting.CustomTheme.LogoWarning',{size: EventWebPageHelper.LogoMaxSize,height: EventWebPageHelper.LogoMinHeight});
  }
  // Warning message for organizer and banner image
  private GetBackgroundImageWarning() {
    return this.$t('ImageProcess.BackgroundImageMessage',{backgroundImageWidthRatio: EventWebPageHelper.BackgroundImageWidthRatio, backgroundImageHeightRatio: EventWebPageHelper.BackgroundImageHeightRatio, maxBackgroundImageSize: EventWebPageHelper.BackgroundImageMaxSize, backgroundImageWidth: EventWebPageHelper.BackgroundImageMaxWidth, backgroundImageHeight:EventWebPageHelper.BackgroundImageMaxHeight});
  }
  private GetImageUrl() {
    return (this.eventWebPageData.EventWebPagesMetaData.LogoURL === null || this.eventWebPageData.EventWebPagesMetaData.LogoURL === undefined || this.eventWebPageData.EventWebPagesMetaData.LogoURL === '') ? this.missingImage : this.eventWebPageData.EventWebPagesMetaData.LogoURL;
  }
  private GetStartDate() {
    return this.eventWebPageData.EventWebPagesMetaData.StartDate;
  }
  private GetEndDate() {
    return this.eventWebPageData.EventWebPagesMetaData.EndDate;
  }
  private CloseValidationSummary(val: boolean) {
    this.isValidationSummaryVisible = val;
  }
  private CloseVueModalPopUp() {
    this.showVueModalPopUp = false;
  }
  private CloseAnalyticsModalPopup() {
    this.showAnalyticsModalPopup = false;
  }
  private CloseDetailModalPopUp() {
    this.isPopupVisible = false;
  }
  // Setting value for start date
  private SetSelectedStartDate(date: Date) {
    if (date !== null && date !== undefined) {
      // Changing the value of end date if start date exceeds end date
      if(date > this.eventWebPageData.EventWebPagesMetaData.EndDate) {
        this.eventWebPageData.EventWebPagesMetaData.EndDate = date;
      }
      this.eventWebPageData.EventWebPagesMetaData.StartDate = date;
    }
  }
  // Setting value for end date
  private SetSelectedEndDate(date: Date) {
    if (date !== null && date !== undefined) {
      this.eventWebPageData.EventWebPagesMetaData.EndDate = date;
    }
  }
  // Setting value of about section description
  private SetAboutDescription(description: string) {
    if (description !== null && description !== undefined) {
      this.eventWebPageData.EventWebPagesMetaData.AboutSectionDescription = description;
    }
  }
  // Setting value of organizer section description
  private SetOrganizerDescription(description: string) {
    if (description !== null && description !== undefined) {
      this.eventWebPageData.EventWebPagesMetaData.OrganizerDescription = description;
    }
  }
  // Used for disabling fields when either it is mobile view or the section is unchecked
  private DisableFields(val: boolean) {
    return this.isMobileView || val;
  }
  // Used for preventing the change in the button url when either it is 'use ventla invitee' or it is mobile view
  private DisableCTAButtonUrl() {
    return this.eventWebPageData.EventWebPagesMetaData.CTAButtonType === EventWebPageButtonType.VentlaInviteLink || this.isMobileView;
  }
  // Used for showing message when selected button type is 'use ventla invitee' and invitee is not published
  private IsInviteePublished() {
    return !this.eventWebPageData.IsInviteePublished && this.eventWebPageData.EventWebPagesMetaData.CTAButtonType === EventWebPageButtonType.VentlaInviteLink;
  }
  private CopyPublicLinkToClipboard() {
    this.showCopiedMessage = Common.copyPublicLinkToClipboard(
      this.GetPublishedLink(),
    );
    if (this.showCopiedMessage) {
      setTimeout(() => {
        this.showCopiedMessage = false;
      }, this.linkCopiedFadeoutTime);
    }
  }
  private GetPublishedLink() {
    return this.staticPath + this.eventWebPageData.AppEncodedName + '/' + this.eventWebPageData.EventWebPages.EncodedPath;
  }
  // Used for showing preview page
  private GetPreviewLink() {
    return this.staticPath + this.eventWebPageData.AppEncodedName + '/' + this.eventWebPageData.EventWebPages.EncodedPath + '/' + this.eventWebPageData.EventWebPages.PreviewGUID;
  }
  private SaveAndPublish() {
    this.isPublished = true;
    this.Save();
  }
  private Publish(data: DetailModalPopupViewModel) {
    // Setting the values received
    this.eventWebPageData.EventWebPages.HidePageFromSearchEngines = data.HidePageFromSearchEngines;
    this.eventWebPageData.EventWebPages.Path = data.Path;
    this.CloseDetailModalPopUp();
    this.SetModalPopupData();
    if(!this.eventWebPageData.EventWebPages.IsPublished) {
      this.eventWebPageData.EventWebPages.Id = 0;
    }
    if(data.Button2Text === this.$t('Publish').toString()) {
      this.isPublished = true;
      this.Save();
    }
    this.SavePublishSettings();
  }
  // Save publish settings(i.e, path, hide from search engine)
  private SavePublishSettings() {
    if(this.eventWebPageData.EventWebPages.IsPublished) {
      this.showLoading = true;
    }
    this.$axios
      .post(
        '/EventWebPage/SavePublishSettingsOfEventWebPage',
        {
          path: this.eventWebPageData.EventWebPages.Path,
          hidePageFromSearchEngines: this.eventWebPageData.EventWebPages.HidePageFromSearchEngines,
        },
      ).then((response)=> {
        if(response && this.eventWebPageData.EventWebPages.IsPublished) {
          this.eventWebPageData.EventWebPages.EncodedPath = this.oldEventWebPageData.EventWebPages.EncodedPath = response.data;
          this.detailModalPopupData.EncodedPath = response.data;
          this.ShowToasterAndLoader(this.$t('SavedSuccessfully').toString());
          this.oldEventWebPageData.EventWebPages.Path = this.eventWebPageData.EventWebPages.Path;
          this.oldEventWebPageData.EventWebPages.HidePageFromSearchEngines = this.eventWebPageData.EventWebPages.HidePageFromSearchEngines;
        }
      }).catch(()=> {
        this.showLoading = false;
      });
  }
  // Used for unpublishing the page
  private Unpublish() {
    this.showLoading = true;
    this.$axios
      .post(
        '/EventWebPage/UnpublishEventWebPage',
      ).then((response)=> {
        if(response) {
          this.isPublished = false;
          this.eventWebPageData.EventWebPages.IsPublished = false;
          this.oldEventWebPageData.EventWebPages.IsPublished = false;
          this.detailModalPopupData.Button2Text = this.$t('Publish').toString();
          this.ShowToasterAndLoader(this.$t('UnPublished').toString());
        }
      }).catch(()=> {
        this.showLoading = false;
      });
  }
  private ShowPopup() {
    if(this.eventWebPageData.EventWebPages.IsPublished) {
      this.ShowVueModalPopUp();
    } else {
      this.ShowDetailModalPopUp();
    }
  }
  // For publish, save & publish
  private ShowVueModalPopUp() {
    // Setting the popoup data
    this.publishModalPopUp = new VueModalPopUpViewModel(
      this.$t('Publish').toString(),
      this.$t('EventWebpage.VueModalPopUpSavedDraftPageDescription').toString(),
      false,
      '',
      this.$t('CancelButton').toString(),
      this.$t('Publish').toString(),
    );
    this.isSavedAndPublished = false;
    if(this.isPageDirty) {
      // If page is dirty then it is the case of save and publish and hence setting the data for popup accordingly
      this.publishModalPopUp.Button2Text = this.$t('SaveAndPublish').toString();
      this.isSavedAndPublished = true;
      this.publishModalPopUp.ModelDescription = this.$t('EventWebpage.VueModalPopUpUnSavedDraftPageDescription').toString();
    }
    this.showVueModalPopUp = true;
  }
  // For publish and save publish settings(i.e., path, hide from searh engine)
  private ShowDetailModalPopUp() {
    if(this.eventWebPageData.EventWebPages.IsPublished) {
      this.detailModalPopupData.Button2Text = this.$t('Button.Save').toString();
      this.isSavedAndPublished = false;
    }
    this.isPopupVisible = true;
  }
  // Used for checking whether page contains unsaved data
  private IsPageDirty() {
    const objectState = (JSON.stringify(this.oldEventWebPageData) === JSON.stringify(this.eventWebPageData));
    if (!objectState) {
      this.isPageDirty = true;
    } else {
      this.isPageDirty = false;
    }
  }
  // Checking whether any required field is empty
  private ValidateData() {
    // Taken const variable to shorten the length of checks
    const self = this.eventWebPageData.EventWebPagesMetaData;
    if((self.AboutSectionTitle === '' || self.AboutSectionTitle === null) && self.ShowAboutSection || (self.ScheduleSectionTitle === '' || self.ScheduleSectionTitle === null) && self.ShowScheduleSection || (self.OrganizerSectionTitle === '' || self.OrganizerSectionTitle === null) && self.ShowOrganizer || (self.VenueSectionTitle === '' || self.VenueSectionTitle === null) && self.ShowVenue || (self.CTAButtonTitle === '' || self.CTAButtonTitle === null) && self.CTAButtonType !== EventWebPageButtonType.NoAction || (self.SponsorSectionTitle === '' || self.SponsorSectionTitle === null) && self.ShowSponsors || (self.SpeakerSectionTitle === '' || self.SpeakerSectionTitle === null) && self.ShowSpeakers) {
      this.validationErrorFields.push(this.$t('Title').toString());
    }
    if(self.FooterText === '' || self.FooterText === null) {
      this.validationErrorFields.push(this.$t('FooterText').toString());
    }
    if((self.AboutSectionDescription === '' || self.AboutSectionDescription === null) && self.ShowAboutSection || (self.OrganizerDescription === '' || self.OrganizerDescription === null) && self.ShowOrganizer) {
      this.validationErrorFields.push(this.$t('Description').toString());
    }
    if(self.BannerImageURL === '' || self.BannerImageURL === null) {
      this.validationErrorFields.push(this.$t('BannerImage').toString());
    }
    if((self.OrganizerImageUrl === '' || self.OrganizerImageUrl === null) && self.ShowOrganizer) {
      this.validationErrorFields.push(this.$t('OrganizerImage').toString());
    }
    if(self.LogoURL === '' || self.LogoURL === null) {
      this.validationErrorFields.push(this.$t('Logo').toString());
    }
    if((self.Address === '' || self.Address === null) && self.ShowVenue) {
      this.validationErrorFields.push(this.$t('StreetAddress').toString());
    }
    if((self.City === '' || self.City === null) && self.ShowVenue) {
      this.validationErrorFields.push(this.$t('City').toString());
    }
    if(!this.isButtonUrlValid && self.CTAButtonType !== EventWebPageButtonType.NoAction) {
      this.validationErrorFields.push(this.$t('InvalidUrl').toString());
    }
  }
  // Used for validating input fields and making border red if invalid
  private ValidateInputField(name: string, isFieldEmpty: boolean, isRequired: boolean) {
    return this.validationErrorFields.includes(name) && isFieldEmpty && isRequired;
  }
  // Saving the data
  private Save() {
    this.validationErrorFields = [];
    this.CloseVueModalPopUp();
    this.ValidateData();
    if(this.validationErrorFields.length !==0) {
      this.isValidationSummaryVisible = true;
      Common.ScrollToTop('dvEventWebPageParent');
    } else {
      const toasterMessage = this.isPublished ? this.$t('PublishedSuccessfully').toString() : this.$t('SavedSuccessfully').toString();
      const reqInfo: AppRequestInfo = this.storeHelper.GetRequestInfoFromStore();
      this.eventWebPageData.IsEventWebPageEnabled = reqInfo.EnableEventWebPage;
      this.eventWebPageData.IsEventWebPagePFActive = reqInfo.IsEventWebPagePFActive;
      this.showLoading = true;
      this.isValidationSummaryVisible = false;
      const objForJson = {
        Latitude: this.eventWebPageData.EventWebPagesMetaData.Latitude,
        Longitude: this.eventWebPageData.EventWebPagesMetaData.Longitude,
      };
      this.$axios
        .post<EventWebPageViewModel>(
        '/EventWebPage/SaveOrUpdateEventWebPages',
        {
          eventWebPagePublicViewDto: this.eventWebPageData,
          isPublished: this.isPublished,
          isSavedAndPublished: this.isSavedAndPublished,
          jsonData : JSON.stringify(objForJson),
        },
      )
        .then((response) => {
          if(response.data) {
            this.GetEventWebPageData();
            this.ShowToasterAndLoader(toasterMessage);
            this.isPublished = false;
            this.isSavedAndPublished = false;
            this.externalLinkUrl = '';
          } else {
            this.showLoading = false;
          }
        })
        .catch(() => {
          this.showLoading = false;
        });
    }
  }
  // Common function for showing and hiding toaster message and loader
  private ShowToasterAndLoader(toasterMessage: string) {
    this.toasterMessage = toasterMessage;
    this.showToaster = true;
    setTimeout(() => {
      this.showLoading = false;
      setTimeout(() => {
        this.showToaster = false;
      }, 2000);
    }, 1000);
  }
  // Discarding the changes made
  private Discard() {
    this.eventWebPageData = JSON.parse(JSON.stringify(this.oldEventWebPageData));
    this.isValidationSummaryVisible = false;
    this.validationErrorFields = [];
    this.externalLinkUrl = '';
  }
  private getInformationString(val: boolean) {
    this.showOverlayInfo = val;
  }
  // Watches for handling the change in palettes colors
  @Watch('PrimaryBackgroundColor')
  private CheckChangeInPrimaryBackgroundColor(newColor: { hex: '' }) {
    this.eventWebPageData.EventWebPageThemeSettings.PrimaryBackgroundColor = newColor.hex;
  }
  @Watch('PrimaryFontColor')
  private CheckChangeInPrimaryFontColor(newColor: { hex: '' }) {
    this.eventWebPageData.EventWebPageThemeSettings.PrimaryFontColor = newColor.hex;
  }
  @Watch('HeaderFooterBackgroundColor')
  private CheckChangeInHeaderFooterBackgroundColor(newColor: { hex: '' }) {
    this.eventWebPageData.EventWebPageThemeSettings.HeaderFooterBackgroundColor = newColor.hex;
  }
  @Watch('HeaderFooterFontColor')
  private CheckChangeInHeaderFooterFontColor(newColor: { hex: '' }) {
    this.eventWebPageData.EventWebPageThemeSettings.HeaderFooterFontColor = newColor.hex;
  }
  @Watch('ButtonAccentBackgroundColor')
  private CheckChangeInButtonAccentBackgroundColor(newColor: { hex: '' }) {
    this.eventWebPageData.EventWebPageThemeSettings.ButtonAccentBackgroundColor = newColor.hex;
  }
  @Watch('ButtonAccentFontColor')
  private CheckChangeInButtonAccentFontColor(newColor: { hex: '' }) {
    this.eventWebPageData.EventWebPageThemeSettings.ButtonAccentFontColor = newColor.hex;
  }
  @Watch('LinkFontColor')
  private CheckChangeInLinkFontColor(newColor: { hex: '' }) {
    this.eventWebPageData.EventWebPageThemeSettings.LinkFontColor = newColor.hex;
  }
}
