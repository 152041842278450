






















































































































import Common from '@/helper/Common';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DetailModalPopupViewModel } from '@/entities/DetailModalPopupViewModel';
import EventWebPageHelper from '@/helper/EventWebPageHelper';
@Component
export default class DetailModalPopup extends Vue {
  @Prop() private DetailModalPopupData!: DetailModalPopupViewModel;
  private copiedIcon: string = require('@/assets/Images/link_icon_small.svg');
  private localDetailModalPopupData: DetailModalPopupViewModel = DetailModalPopupViewModel.createEmpty();
  private showCopiedMessage = false;
  private linkCopiedFadeoutTime = 500;
  private isPathValid = true;
  private maxPathLength = EventWebPageHelper.MaxPathLength;
  private urlGenerated = false;
  private loadingImage: string = require('@/assets/Images/loading.png');
  private mounted() {
    this.localDetailModalPopupData = Object.assign({},this.DetailModalPopupData);
  }
  private CopyPublicLinkToClipboard() {
    this.showCopiedMessage = Common.copyPublicLinkToClipboard(
      this.GetLink(),
    );
    if (this.showCopiedMessage) {
      setTimeout(() => {
        this.showCopiedMessage = false;
      }, this.linkCopiedFadeoutTime);
    }
  }
  private GetLink() {
    if(this.localDetailModalPopupData !== null && this.localDetailModalPopupData !== undefined) {
      return this.localDetailModalPopupData.StaticPath + this.localDetailModalPopupData.AppEncodedName + '/' + this.localDetailModalPopupData.EncodedPath;
    } else {
      return '';
    }
  }
  private GetPath() {
    if(this.localDetailModalPopupData !== null && this.localDetailModalPopupData !== undefined) {
      return this.localDetailModalPopupData.StaticPath + this.localDetailModalPopupData.AppEncodedName + '/<b>' + this.localDetailModalPopupData.EncodedPath + '</b>';
    }
  }
  private ValidatePath() {
    if(this.localDetailModalPopupData !== null && this.localDetailModalPopupData !== undefined) {
      this.isPathValid = !this.localDetailModalPopupData.EventsPath.includes(this.localDetailModalPopupData.Path.toLowerCase());
    }
    if(this.localDetailModalPopupData.Path === '') {
      this.isPathValid = false;
      this.localDetailModalPopupData.InvalidUrlMessage = this.$t('EventWebpage.EmptyUrl').toString();
    } else {
      this.localDetailModalPopupData.InvalidUrlMessage = this.DetailModalPopupData.InvalidUrlMessage;
    }
  }
  private Cancel(): void {
    // close modal pop up and emit to parent component
    this.$emit('close-popup');
  }
  private Save() {
    // save modal pop up and emit to parent component
    this.ValidatePath();
    if(this.isPathValid) {
      this.$emit('publish', this.localDetailModalPopupData);
    }
  }

  private GetEncodedPath() {
    this.urlGenerated = true;
    this.localDetailModalPopupData.Path = this.localDetailModalPopupData.Path.replace('+', '').replace('/', '').replace('\\', '');
    this.$axios
      .post('/Common/GetEncodedPath',{
        path: this.localDetailModalPopupData.Path,
      })
      .then((response) => {
        this.localDetailModalPopupData.EncodedPath = response.data;
        this.urlGenerated = false;
      })
      .catch(() => {
        // Do nothing
      });
  }
}
