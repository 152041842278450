




































































import { Component, Prop, Vue } from 'vue-property-decorator';
import AnalyticsModalPopupViewModel from '@/entities/AnalyticsModalPopupViewModel';

@Component
export default class AnalyticsModalPopup extends Vue {
  @Prop() AnalyticsModalPopUp!: AnalyticsModalPopupViewModel;
  private localAnalyticsModalPopUp: AnalyticsModalPopupViewModel = AnalyticsModalPopupViewModel.createEmpty();
  private mounted() {
    this.localAnalyticsModalPopUp = Object.assign({}, this.AnalyticsModalPopUp);
  }
  private Cancel(): void {
    // close modal pop up and emit to parent component
    this.$emit('close');
  }
}
